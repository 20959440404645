import { Alert, Col, Tooltip } from 'antd'
import moment from 'moment'
import GoogleMapReact from 'google-map-react'
import { RiMapPinTimeLine } from 'react-icons/ri'
import { ContainerMap, Map, PinCircule, ContainerAdress } from '../ModalKanban/style'
import { BsFlagFill } from 'react-icons/bs'
import { BiHome } from 'react-icons/bi'
import Geocode from 'react-geocode'
import { useState } from 'react'
import { generateUUID } from '../../utils/funcao'
import { estadosBrasileiros } from '../../utils/latUs'
import { FaThumbtack } from 'react-icons/fa'

Geocode.setApiKey('AIzaSyAD5hm6GTtOD0rVYAJdSHvc9YvQ73eI2mc')
Geocode.setRegion('br')

interface IProps {
  data: {
    pdv: {
      latitude: string
      longitude: string
      address: string
      neighborhood: string
      city: string
      cep: string
    }
    check_in_date: string
    status: string
  }[]
  location: {
    latitude: string
    longitude: string
    created_at: any
  }[]
  adress: {
    lat: string
    lng: string
  },
  state: string
}
export const MapKanbam: React.FC<IProps> = ({ data, location, adress, state }: IProps) => {
  const [tooltipTime, setTooltipTime] = useState<string>('06:00')

  const AnyReactComponent = ({ text }: any) => {
    const date = moment(text, 'DD/MM/YYYY HH:mm:ss')
    const hours = date.hours()
    // Função para calcular a cor de acordo com o horário
    const calculateColor = () => {
      if (hours >= 6 && hours < 12) {
        // Manhã: De #6600cc a #FF2A84
        const startColor = [102, 0, 204] // #6600cc
        const endColor = [255, 42, 132] // #FF2A84
        const progress = (hours - 6) / 6
        const color = startColor.map((startValue, index) =>
          Math.round(startValue + (endColor[index] - startValue) * progress)
        )
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`
      } else if (hours >= 12 && hours < 20) {
        // Tarde: De #FF2A84 a #FFCB53
        const startColor = [255, 42, 132] // #FF2A84
        const endColor = [255, 203, 83] // #FFCB53
        const progress = (hours - 12) / 8
        const color = startColor.map((startValue, index) =>
          Math.round(startValue + (endColor[index] - startValue) * progress)
        )
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`
      } else {
        // Noite: #6600cc
        return '#6600cc'
      }
    }

    const pinCircleStyle = {
      backgroundColor: calculateColor(),
      // Outros estilos do componente PinCircule aqui...
    }

    return (
      <Tooltip placement='top' title={text}>
        <PinCircule style={pinCircleStyle} />
      </Tooltip>
    )
  }

  const LastCheckin = ({ text }: any) => (
    <Tooltip placement='top' title={`Última localização: ${text}`}>
      <RiMapPinTimeLine fontSize='25px' color='#7700f5' />
    </Tooltip>
  )

  const PinPDV = ({ text, status }: any) => (
    <Tooltip placement='top' title={`PDV: ${text} ${status == 'PENDENT' ? ' | Pendente ' : ''}`}>
      <BsFlagFill
        fontSize='25px'
        color={
          status === 'COMPLETE'
            ? 'rgb(0, 196, 159)'
            : status === 'IN_PROGRESS'
              ? 'rgb(255, 187, 40)'
              : status === 'JUSTIFIED_ABSENCE'
                ? 'rgb(255, 128, 66)'
                : 'rgb(0, 136, 254)'
        }
      />
    </Tooltip>
  )

  const PinCheck = ({ text, status }: any) => (
    <Tooltip placement='top' title={`${text}`}>
      <FaThumbtack
        fontSize='15px'
        color={
          status === 'checkin'
            ? '#7158c1'
            : '#00b894'
        }
      />
    </Tooltip>
  )

  const PinHome = ({ text, status }: any) => (
    <Tooltip placement='top' title={`${text}`}>
      <BiHome fontSize='25px' />
    </Tooltip>
  )

  const handleGoogleMapApi = (google: any, dataTasks: any) => {
    if (!data[0].pdv?.latitude || !data[0].pdv?.longitude) return
    let pdvs = data.filter((item) => item.pdv?.latitude && item.pdv?.longitude && item.pdv?.latitude != '0' && item.pdv?.longitude != '0' && item.status != 'PENDENT')
    let listPdvs = pdvs.sort(
      (a, b) => moment(a.check_in_date).toDate().getTime() - moment(b.check_in_date).toDate().getTime()
    )

    let directionsService = new google.maps.DirectionsService()
    var directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    })
    let points = []
    listPdvs.forEach((list: any, index: number) => {
      if (index === listPdvs.length - 1) return
      points.push({
        location: new google.maps.LatLng(list.pdv?.latitude, list.pdv?.longitude),
        stopover: true,
      })
    })

    if (listPdvs.length > 0) {
      directionsDisplay.setMap(google.map)
      directionsService.route(
        {
          travelMode: 'DRIVING',
          origin: new google.maps.LatLng(
            adress.lat ? adress.lat : listPdvs[0].pdv.latitude,
            adress.lng ? adress.lng : listPdvs[0].pdv.longitude
          ),
          waypoints: points,
          destination: new google.maps.LatLng(
            listPdvs[listPdvs.length - 1].pdv.latitude,
            listPdvs[listPdvs.length - 1].pdv.longitude
          ),
        },
        (DirectionsResult: any, DirectionsStatus: any) => {
          console.log(DirectionsResult)
          if (DirectionsResult.status === 'OK') {
            directionsDisplay.setDirections(DirectionsResult)
          }
        }
      )
    }

  }

  const handleMouseMove = (event) => {
    const width = event.target.clientWidth
    const offsetX = event.nativeEvent.offsetX
    const percentage = (offsetX / width) * 100

    const startHour = 6
    const endHour = 20
    const currentHour = startHour + ((endHour - startHour) * percentage) / 100

    const formattedHour = currentHour < 10 ? `0${Math.floor(currentHour)}:00` : `${Math.floor(currentHour)}:00`
    setTooltipTime(formattedHour)
  }

  return (
    <Col style={{ height: '100%' }}>
      <ContainerMap>
        {!adress.lat && (
          <Alert
            message={'Promotor sem endereço residencial cadastrado'}
            type='warning'
            showIcon
            style={{ marginBottom: 10 }}
          />
        )}
        <Map>
          {data.length > 0 ? (
            <GoogleMapReact
              key={generateUUID()}
              bootstrapURLKeys={{
                key: 'AIzaSyAD5hm6GTtOD0rVYAJdSHvc9YvQ73eI2mc',
              }}
              center={{
                lat:
                  data.length > 0 && data[0].pdv?.latitude && data[0].pdv?.longitude
                    ? parseFloat(data[0].pdv?.latitude)
                    : location &&
                      location.length > 0 &&
                      location[location.length - 1].latitude &&
                      location[location.length - 1].longitude
                      ? parseFloat(location[location.length - 1].latitude)
                      : state ? estadosBrasileiros.find(item => item.sigla == state)?.latitude
                        : -23.688006851526705,
                lng:
                  data.length > 0 && data[0].pdv?.latitude && data[0].pdv?.longitude
                    ? parseFloat(data[0].pdv?.longitude)
                    : location &&
                      location.length > 0 &&
                      location[location.length - 1].latitude &&
                      location[location.length - 1].longitude
                      ? parseFloat(location[location.length - 1].longitude)
                      : state ? estadosBrasileiros.find(item => item.sigla == state)?.longitude
                        : -46.5636137,
              }}
              zoom={15}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(google: any) => handleGoogleMapApi(google, data)}
            >
              {location &&
                location.length > 0 &&
                location.map((geo_location: any) => (
                  <AnyReactComponent
                    lat={geo_location.latitude}
                    lng={geo_location.longitude}
                    text={moment(geo_location.created_at).format('DD/MM/YYYY HH:mm:ss')}
                  />
                ))}
              {console.log('data', data)}
              {location && location.length > 0 && (
                <LastCheckin
                  lat={location[location.length - 1].latitude}
                  lng={location[location.length - 1].longitude}
                  text={moment(location[location.length - 1].created_at).format('DD/MM/YYYY HH:mm:ss')}
                />
              )}





              {data &&
                data.length > 0 &&
                data.map(({ pdv, status, check_in, check_in_latitude, check_in_longitude, check_out_latitude, check_out_longitude }: any) => {
                  if (check_in_latitude && check_in_longitude) return <PinCheck
                    lat={check_in_latitude}
                    lng={check_in_longitude}
                    text={`Local do check-in ${check_in}
                      PDV: ${pdv.name}
                    `}
                    status='checkin'
                  />
                  return null
                })}

              {data &&
                data.length > 0 &&
                data.map(({ pdv, status, check_in,check_out, check_in_latitude, check_in_longitude, check_out_latitude, check_out_longitude }: any) => {
                  if (check_out_latitude && check_in_longitude) return <PinCheck
                    lat={check_out_latitude}
                    lng={check_in_longitude}
                    text={`Local do check-out ${check_out}
                        PDV: ${pdv.name}
                    `}
                    status='check-ou'
                  />
                  return null
                })}

              {data &&
                data.length > 0 &&
                data.map(({ pdv, status, check_in, check_in_latitude, check_in_longitude, check_out_latitude, check_out_longitude }: any) => {

                  return <PinPDV
                    lat={pdv.latitude ? pdv.latitude : ''}
                    lng={pdv.longitude ? pdv.longitude : ''}
                    text={pdv.name ? pdv.name : ''}
                    status={status ? status : ''}
                  />
                })}




              {adress && adress.lat && adress.lng && (
                <PinHome lat={adress.lat ? adress.lat : ''} lng={adress.lng ? adress.lng : ''} text={'CASA'} />
              )}
            </GoogleMapReact>
          ) : (
            <>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: 'AIzaSyAD5hm6GTtOD0rVYAJdSHvc9YvQ73eI2mc',
                }}
                center={{
                  lat:
                    location &&
                      location.length > 0 &&
                      location[location.length - 1].latitude &&
                      location[location.length - 1].longitude
                      ? parseFloat(location[location.length - 1].latitude)
                      : state ? estadosBrasileiros.find(item => item.sigla == state)?.latitude
                        : -23.688006851526705,
                  lng:
                    location &&
                      location.length > 0 &&
                      location[location.length - 1].latitude &&
                      location[location.length - 1].longitude
                      ? parseFloat(location[location.length - 1].longitude)
                      : state ? estadosBrasileiros.find(item => item.sigla == state)?.longitude
                        : -46.5636137,
                }}
                zoom={15}
                yesIWantToUseGoogleMapApiInternals
              >
                {location &&
                  location.length > 0 &&
                  location.map((geo_location: any) => (
                    <AnyReactComponent
                      lat={geo_location.latitude}
                      lng={geo_location.longitude}
                      text={moment(geo_location.created_at).format('DD/MM/YYYY HH:mm:ss')}
                    />
                  ))}

                {adress && adress.lat && adress.lng && (
                  <PinHome lat={adress.lat ? adress.lat : ''} lng={adress.lng ? adress.lng : ''} text={'CASA'} />
                )}
              </GoogleMapReact>
            </>
          )}
        </Map>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: -10,
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: 534,
          }}
        >
          <div>
            <PinCircule />
            <p>Localização real-time (ping ping)</p>
          </div>
          <div>
            <div
              style={{
                height: 5,
                width: 30,
                borderRadius: 2,
                backgroundColor: 'rgba(79, 169, 248, 0.67)',
              }}
            />
            <p>Trajeto sugerido</p>
          </div>
          <div>
            <div
              style={{
                height: '5px',
                width: '100px', // Ajuste o tamanho conforme necessário
                borderRadius: '2px',
                background: 'linear-gradient(to right, rgb(102, 0, 204), rgb(255, 42, 132), rgb(255, 203, 83))',
                marginRight: '8px',
              }}
              onMouseMove={handleMouseMove}
            >
              <Tooltip placement='top' title={tooltipTime}>
                <div style={{ width: '100%', maxWidth: 150, height: '100%' }} />
              </Tooltip>
            </div>
            <p>Horário</p>
          </div>
        </div>

        <ContainerAdress>
          {data.length > 0 &&
            `${data[0].pdv.address}, ${data[0].pdv.neighborhood}, ${data[0].pdv.city} | ${data[0].pdv.cep}`}
        </ContainerAdress>
      </ContainerMap>
    </Col >
  )
}
