import React, { useState } from 'react'
import DefaultPage from '../../../components/DefaultPage'
import { isActiveSelectVisita } from '../../../utils/option-user'
import { DataStatus } from '../../../components/TabsVisits/style'
import { compareByAlph, exportXls } from '../../../utils/funcoes'
import { getStatus } from '../../Survey/VisitsPage'
import moment from 'moment'
import { Col, Modal, Row } from 'antd'
import { BsEye } from 'react-icons/bs'
import qs from 'querystring'
import useReactRouter from 'use-react-router'

interface IPropsInfo {
  visible: boolean
  info: any
}

export const HistoryScript = () => {
  const { location } = useReactRouter()

  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState<IPropsInfo>({
    visible: false,
    info: {},
  })

  const diasDaSemana = {
    MONDAY: "Segunda-feira",
    TUESDAY: "Terça-feira",
    WEDNESDAY: "Quarta-feira",
    THURSDAY: "Quinta-feira",
    FRIDAY: "Sexta-feira",
    SATURDAY: "Sábado",
    SUNDAY: "Domingo"
  }

  const frequency = {
    FORTNIGHTLY: "Quinzenal",
    MONTHLY: "Mensal",
    WEEKLY: "Semanal",
  }

  const numberWeek = {
    ONE: "1",
    TWO: "2",
    FOUR: "3",
    THRE: "4",
  }

  let convertUrlToExportXls = (defaultUrl?: any) => {
    try {
      let getUrl: any = qs.parse(location.search.replaceAll('?', ''))

      let filterQuery: any = {}
      for (var el in getUrl) {
        filterQuery[el] = JSON.parse(getUrl[el]).value
      }

      console.log(filterQuery)

      return filterQuery
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
      <DefaultPage
        titlePage='Histórico de roteiro'
        breadcrumb={[{ name: 'Home', url: '/' }, { name: 'Histórico de roteiro' }]}
        urlPage='/backoffice/history/script'
        routeApi={{
          method: 'get',
          route: '/script/history',
          versionApi: 'v2',
          reload: false,
        }}
        listButtons={{
          buttonFilter: {
            visible: true,
            filters: [
              {
                label: 'Selecione o nome do promotor:',
                name: 'promoter_id',
                type: 'search',
                urlSearch: 'promoters',
                getEnv: 'value',
              },
              {
                label: 'Selecione o nome do pdv:',
                name: 'pdv_id',
                type: 'search',
                urlSearch: 'pdvs',
                getEnv: 'label',
              }
            ],
          },
          buttonExport: {
            visible: true,
            type: 'function',
            function: async () => {
              setLoading(true)
              await exportXls(convertUrlToExportXls(), `history-script`)
              setLoading(false)
            },
          },
        }}
        load={{ loading, setLoading }}
        columns={[
          {
            title: 'Quem editou',
            dataIndex: 'user_name',
            sorter: (a, b) => compareByAlph(a.user_id, b.user_id),
          },
          {
            title: 'Nome do promotor',
            dataIndex: 'promoter_name',
            sorter: (a, b) => compareByAlph(a.promoter_name, b.promoter_name),
          },
          {
            title: 'Nome PDV',
            dataIndex: 'pdv_name',
            sorter: (a, b) => compareByAlph(a.log_body[0].pdv_name, b.log_body[0].pdv_name),
            render: (a, b) => (
              <>
                {b.log_body[0].pdv_name}
              </>
            )
          },
          {
            title: 'Local',
            dataIndex: 'location',
            sorter: (a, b) => compareByAlph(a.location, b.location),
          },
          {
            title: 'Ação',
            dataIndex: 'action',
            sorter: (a, b) => compareByAlph(a.location, b.location),
            render:(a: any) => (
              <>
                {a == 'DELETED' && 'APAGOU'}
                {a == 'UPDATED' && 'ATUALIZOU'}
                {a == 'COPY' && 'COPIOU'}
                {a == 'CREATED' && 'CRIOU'}
              </>
            )
          },
          {
            title: 'Data da edição',
            dataIndex: 'created_at',
            sorter: (a, b) => compareByAlph(a.created_at, b.created_at),
            render: (value: any) => moment(value).format('DD/MM/YYYY'),
          },
          {
            title: '#',
            render: (value: any) => (
              <>
                <BsEye
                  style={{
                    cursor: 'pointer',
                    fontSize: 18,
                  }}
                  onClick={() =>
                    setInfo({
                      visible: true,
                      info: value,
                    })
                  }
                />
              </>
            ),
          },
        ]}
      />

      <Modal
        visible={info.visible}
        onCancel={() =>
          setInfo({
            visible: false,
            info: {},
          })
        }
        destroyOnClose={true}
        footer={null}
      >
        <p style={{ textAlign: 'center', fontSize: '20px', marginBottom: 20 }}>Edição realizada</p>
        {info.info && info.info.log_body && info.info.log_body.length > 0 && info.info.log_body.map((item) => (
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Quem editou:</p>
              <p>{info.info.user_name}</p>
            </Col>
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Promotor:</p>
              <p>{info.info.promoter_name}</p>
            </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>PDV:</p>
            <p>{item.pdv_id}</p>
          </Col>


          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Dia da semana:</p>
            <p>{diasDaSemana[item.weekDay]}</p>
          </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Frequência:</p>
            <p>{frequency[item.frequency]}</p>
          </Col>

          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Número da semana:</p>
            <p>{numberWeek[item.weekNumber]}</p>
          </Col>
          {item.sub_workspaces &&
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Empresas:</p>
              <p>{item.sub_workspaces}</p>
            </Col>
          }
            <Col span={12}>
              <p style={{ fontWeight: 'bold' }}>Locao:</p>
              <p>{info.info.location}</p>
            </Col>
          <Col span={12}>
            <p style={{ fontWeight: 'bold' }}>Data de edição:</p>
            <p>{moment(info.info.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
          </Col>
        </Row>
        ))}
      </Modal>
    </>
  )
}
