import React, { useState, useEffect, useRef } from 'react'
import { Button, ContainerModal, ContainerStep2, LabelCheck, ContainerBodyModal } from './style'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { Collapse, Drawer, message, Tooltip, Modal } from 'antd'
import { Loading } from '../Loading'
import api_v2 from '../../services/api-v2'
import { Search } from '../form/SimpleSearch'
import { ContainerLogo } from '../ModalSugestion/style'
import { FormOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { BsEraser } from 'react-icons/bs'
import { INIT_GLOBAL_RESPONSE } from '../../utils/global-response'
import { Mixpanel } from '../../utils/mixpanel'
import { hasIndex } from '../Menu/menuHorizonte'
import { ContextUpload } from '../../context/contextUpload'
import { addIsVisit } from '../../utils/addScript'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  visit: any
  reloadApi?: (boolean: any) => void
}

export const ModalCreateTask = ({ visible, close, reloadApi, visit }: IProps) => {
  const { permissions } = ContextUpload()
  const formRef = useRef<FormHandles>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [typeOpen, setTypeOpen] = useState<string>('')
  const [dataTasks, setDataTasks] = useState<any>(INIT_GLOBAL_RESPONSE)

  async function handleSubmit(data: any) {
    setLoading(true)
    try {
      const { forms } = data
      if (!forms || forms.length === 0) {
        message.error('Por favor, adicione ao menos um formulario.')
        setLoading(false)
        return
      }
      for await (let form_id of forms) {
        let exist = false

        dataTasks.result.forEach((task: any) => {
          if (task[0].form_id === form_id.value) exist = true
        })

        if (exist === false) {
          await api_v2.post('/task/add', { form_id: form_id.value, visit_id: visit.id })
          Mixpanel.track('Adicionou tarefa', {
            params: { form_id: form_id.value, visit_id: visit.id },
          })
        } else {
          message.info(`Formulário ${form_id.label} já existe na visita.`)
        }
      }

      message.success('Atividade adicionada com sucesso')
      getTask()
      setOpenDrawer(!openDrawer)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao adicionar atividade!')
      }
    }
    setLoading(false)
  }

  async function handleDelete(form_id: any, task_id: any, product_id: number | null) {
    setLoading(true)
    try {
      await api_v2.post('/task/delete', {
        form_id,
        visit_id: visit.id,
        task_id,
        product_id: product_id ? product_id : null,
      })

      message.success('Atividade deletada com sucesso')
      Mixpanel.track('Deletou tarefa', {
        params: {
          form_id,
          visit_id: visit.id,
          task_id,
          product_id: product_id ? product_id : null,
        },
      })
      await getTask()
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao adicionar atividade!')
      }
    }
    setLoading(false)
  }

  async function handleErease(form_id: any, task_id: any, product_id: number | null) {
    setLoading(true)
    try {
      await api_v2.put('/task/reset', {
        form_id,
        visit_id: visit.id,
        task_id,
        product_id: product_id ? product_id : null,
      })

      Mixpanel.track('Resetou tarefa', {
        params: {
          form_id,
          visit_id: visit.id,
          task_id,
          product_id: product_id ? product_id : null,
        },
      })

      message.success('Atividade zerada com sucesso')
      getTask()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao zerar atividade!')
      }
      message.error('Erro ao zerar atividade!')
    }
    setLoading(false)
  }

  async function handleEreaseAllProduct(task: any) {
    setLoading(true)
    try {
      for await (let el of task[0].products) {
        await api_v2.put('/task/reset', {
          form_id: el.form_id,
          visit_id: visit.id,
          task_id: el.task_id,
          product_id: el.id,
        })

        Mixpanel.track('Resetou tarefa', {
          params: {
            form_id: el.form_id,
            visit_id: visit.id,
            task_id: el.task_id,
            product_id: el.product_id,
          },
        })
      }

      message.success('Atividade zerada com sucesso')
      getTask()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao zerar atividade!')
      }
      message.error('Erro ao zerar atividade!')
    }
    setLoading(false)
  }

  async function handleDeleteAllProduct(task: any) {
    try {
      setLoading(true)
      for await (let el of task[0].products) {
        await api_v2.post('/task/delete', {
          form_id: el.form_id,
          visit_id: visit.id,
          task_id: el.task_id,
          product_id: el.id,
        })
        Mixpanel.track('Deletou tarefa', {
          params: {
            form_id: el.form_id,
            visit_id: visit.id,
            task_id: el.task_id,
            product_id: el.product_id,
          },
        })
      }

      message.success('Atividade zerada com sucesso')
      getTask()
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (err instanceof Yup.ValidationError) {
        message.error('Erro ao zerar atividade!')
      }
      message.error('Erro ao zerar atividade!')
    }
  }

  const functionThatResetsForm = () => {
    formRef.current!.reset()
  }

  const getTask = async () => {
    setLoading(true)

    if (visit && visit.id) {
      const { data } = await api_v2.get(`/task?visit_ids=${visit.id}&perpage=100&page=1`)

      let organize: any = []
      let ids: any = []

      data.result.forEach((el: any) => {
        if (ids.filter((has: any) => has === el.form_id).length === 0) {
          organize.push(data.result.filter((id: any) => id.form_id === el.form_id))
          ids.push(el.form_id)
        }
      })

      let result = []

      for await (const item of organize) {
        if(item[0].context == 'Produto') {
          const { data } = await api_v2.post(`/product/all-by-id`, {
            form_id: item[0].form_id,
            visit_id: item[0].visit_id
          })
          item[0].products = data
        } 

        result.push(item)

      }


      setDataTasks({ ...data, result: result })
    }

    setLoading(false)

  }

  useEffect(() => {
    setDataTasks(INIT_GLOBAL_RESPONSE)
    if (formRef.current != null) functionThatResetsForm()
    getTask()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const genStore = (task: any, product_id: number | null) => (
    <>
      {(visit.status !== 'COMPLETE' || visit.status !== 'JUSTIFIED_ABSENCE') && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasIndex(['team.reset_task'], permissions) > -1 && (
            <>
              {visit.status != 'COMPLETE' && (
                <Tooltip placement='top' title='Zerar tarefa'>
                  <BsEraser
                    onClick={(event) => {
                      if(!addIsVisit()) return message.error('Sem autorização')
                      if (visit.status === 'PENDENT') {
                        message.error('A tarefa não tem resposta para ser resetada.')
                        return
                      }
                      Modal.confirm({
                        title: 'Resetar tarefa',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Você tem certeza que deseja resetar essa tarefa?',
                        onOk() {
                          handleErease(task.form_id, task.task_id, product_id)
                        },
                        onCancel() {
                          console.log('Cancel')
                        },
                      })
                      // handleErease(id.form_id, id.task_id)
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation()
                    }}
                    style={{ fontSize: 20, marginRight: 10, cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
            </>
          )}
          {hasIndex(['team.remove_task'], permissions) > -1 && (
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={(event) => {
                if(!addIsVisit()) return message.error('Sem autorização')
                Modal.confirm({
                  title: 'Deletar tarefa',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Você tem certeza que deseja deletar essa tarefa?',
                  onOk() {
                    handleDelete(task.form_id, task.task_id, product_id)
                  },
                  onCancel() {
                    console.log('Cancel')
                  },
                })
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation()
              }}
            />
          )}
        </div>
      )}
    </>
  )


  const genCategory = (task: any, category_id: number | null) => (
    <>
      {(visit.status !== 'COMPLETE' || visit.status !== 'JUSTIFIED_ABSENCE') && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasIndex(['team.reset_task'], permissions) > -1 && (
            <>
              {visit.status != 'COMPLETE' && (
                <Tooltip placement='top' title='Zerar tarefa'>
                  <BsEraser
                    onClick={(event) => {
                      if(!addIsVisit()) return message.error('Sem autorização')
                      if (visit.status === 'PENDENT') {
                        message.error('A tarefa não tem resposta para ser resetada.')
                        return
                      }
                      Modal.confirm({
                        title: 'Resetar tarefa',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Você tem certeza que deseja resetar essa tarefa?',
                        onOk() {
                          //handleErease(task.form_id, task.task_id, product_id)
                        },
                        onCancel() {
                          console.log('Cancel')
                        },
                      })
                      // handleErease(id.form_id, id.task_id)
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation()
                    }}
                    style={{ fontSize: 20, marginRight: 10, cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
            </>
          )}
          {hasIndex(['team.remove_task'], permissions) > -1 && (
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={(event) => {
                if(!addIsVisit()) return message.error('Sem autorização')
                Modal.confirm({
                  title: 'Deletar tarefa',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Você tem certeza que deseja deletar essa tarefa?',
                  onOk() {
                    //handleDelete(task.form_id, task.task_id, product_id)
                  },
                  onCancel() {
                    console.log('Cancel')
                  },
                })
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation()
              }}
            />
          )}
        </div>
      )}
    </>
  )

  const genProduct = (task: any) => (
    <>
      {(visit.status !== 'COMPLETE' || visit.status !== 'JUSTIFIED_ABSENCE') && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {hasIndex(['team.reset_task'], permissions) > -1 && (
            <>
              {task.status != 'COMPLETE' && (
                <Tooltip placement='top' title='Zerar tarefa'>
                  <BsEraser
                    onClick={(event) => {
                      if(!addIsVisit()) return message.error('Sem autorização')
                      if (task.status === 'PENDENT' || task.status === 'COMPLETE') {
                        message.error('A tarefa não tem resposta para ser resetada.')
                        return
                      }
                      Modal.confirm({
                        title: 'Resetar tarefa',
                        icon: <ExclamationCircleOutlined />,
                        content:
                          'Você tem certeza que deseja resetar essa tarefa? Essa ação irá resetar todas os formulários com produtos.',
                        onOk() {
                          handleEreaseAllProduct(task)
                        },
                        onCancel() {
                          console.log('Cancel')
                        },
                      })
                      // handleErease(id.form_id, id.task_id)
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation()
                    }}
                    style={{ fontSize: 20, marginRight: 10, cursor: 'pointer' }}
                  />
                </Tooltip>
              )}
            </>
          )}
          {hasIndex(['team.remove_task'], permissions) > -1 && (
            <DeleteOutlined
              style={{ color: 'red' }}
              onClick={(event) => {
                Modal.confirm({
                  title: 'Deletar tarefa',
                  icon: <ExclamationCircleOutlined />,
                  content:
                    'Você tem certeza que deseja deletar essa tarefa? Essa ação irá apagar todas os formulários com produtos.',
                  async onOk() {
                    await handleDeleteAllProduct(task)
                  },
                  onCancel() {
                    console.log('Cancel')
                  },
                })
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation()
              }}
            />
          )}
        </div>
      )}
    </>
  )

  return (
    <ContainerModal
      visible={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      {loading && <Loading />}

      <ContainerStep2>
        <h3>Visualizar tarefa</h3>
        <ContainerLogo style={{ marginBottom: 10 }}>
          <FormOutlined />
        </ContainerLogo>
        <ContainerBodyModal>
          {dataTasks &&
            dataTasks.result.map((form: any, index: number) => {
              if (form[0] && (form[0].context === 'Loja')) {
                return form.map((el: any) => (
                  <Collapse accordion expandIconPosition='right'>
                    <Collapse.Panel
                      header={`${el.form} ${el.sub_workspace_name ? ` - ${el.sub_workspace_name}` : ''}`}
                      key={index}
                      collapsible='disabled'
                      showArrow={false}
                      extra={genStore(el, null)}
                    />
                  </Collapse>
                ))
              }

              if (form[0] && form[0].context === 'Categoria') {
                return (
                  <Collapse accordion expandIconPosition='right'>
                    <Collapse.Panel header={form[0].form} key={index} showArrow={true} extra={genCategory(form, null)} >
                      {form[0].child.map((el: any) => (
                        <Collapse accordion expandIconPosition='right' >
                          <Collapse.Panel
                            header={el.name}
                            key='2'
                            collapsible='disabled'
                            showArrow={false}
                            extra={genCategory(el, el.name)}
                          />
                        </Collapse>
                      ))}
                    </Collapse.Panel>
                  </Collapse>
                )
              }

              if (form[0] && form[0].context === 'Produto') {
                return (
                  <Collapse accordion expandIconPosition='right'>
                    <Collapse.Panel header={form[0].form} key={index} showArrow={true} extra={genProduct(form)} >
                      {form[0].products.map((el: any) => (
                        <Collapse accordion expandIconPosition='right' >
                          <Collapse.Panel
                            header={el.name}
                            key='2'
                            collapsible='disabled'
                            showArrow={false}
                            extra={genStore(el, el.id)}
                          />
                        </Collapse>
                      ))}
                    </Collapse.Panel>
                  </Collapse>
                )
              }
              return null
            })}

          {/* <Collapse accordion expandIconPosition='right'>
            <Collapse.Panel header='Formulário de preço' key='1' extra={genProduct()}>
              <Collapse accordion expandIconPosition='right'>
                <Collapse.Panel
                  header='Piracanjuba'
                  key='2'
                  collapsible='disabled'
                  showArrow={false}
                  extra={genStore()}
                />
                <Collapse.Panel
                  header='Coca cola'
                  key='2'
                  collapsible='disabled'
                  showArrow={false}
                  extra={genStore()}
                />
              </Collapse>
            </Collapse.Panel>
            <Collapse.Panel
              header='Formulário de loja'
              key='2'
              collapsible='disabled'
              showArrow={false}
              extra={genStore()}
            />
            <Collapse.Panel
              header='Formulário de loja 2'
              key='3'
              collapsible='disabled'
              showArrow={false}
              extra={genStore()}
            />
          </Collapse> */}
        </ContainerBodyModal>
        {hasIndex(['team.add_task'], permissions) > -1 && (
          <>
            {(visit.status !== 'COMPLETE' || visit.status !== 'JUSTIFIED_ABSENCE') && (
              <Button
                type='button'
                style={{ width: '100%', height: '55px', marginTop: 20 }}
                onClick={() => {
                  if(!addIsVisit()) return message.error('Sem autorização')
                  setTypeOpen('store')
                  setOpenDrawer(!openDrawer)
                }}
              >
                Adicionar tarefa
              </Button>
            )}
          </>
        )}
      </ContainerStep2>
      <Drawer
        title='Adicionar tarefa'
        placement='right'
        closable={true}
        onClose={() => setOpenDrawer(!openDrawer)}
        visible={openDrawer}
        // destroyOnClose={true}
        key='right'
        footer={
          <Button
            type='submit'
            style={{ width: '100%', height: '55px', marginTop: 20 }}
            onClick={() => formRef.current?.submitForm()}
          >
            Adicionar
          </Button>
        }
      >
        <Form className='form' ref={formRef} initialData={{ is_active: true }} onSubmit={handleSubmit}>
          {typeOpen === 'store' && (
            <>
              <LabelCheck>Selecione o formulário</LabelCheck>
              <Search
                placeholder='Selecione o formulário'
                name='forms'
                path='forms'
                config='&is_active=true'
                isMult={true}
              />
            </>
          )}
          {typeOpen === 'product' && (
            <>
              <LabelCheck>Selecione o produto</LabelCheck>
              <Search placeholder='Selecione o produto' name='product' path='products' isMult={true} />
            </>
          )}
          <div
            style={{
              marginTop: 50,
              backgroundColor: '#fffbe6',
              border: '1px solid #ffe58f',
              width: '100%',
              padding: 15,
              textAlign: 'justify',
              color: 'rgba(0, 0, 0, 0.85)',
            }}
          >
            <p>
              <b style={{ marginRight: 7 }}>OBS:</b>A criação desta tarefa irá depender do mix de produtos, mix de
              formulários e do agendamento, fique atento as configurações.
            </p>
          </div>
        </Form>
      </Drawer>
    </ContainerModal>
  )
}
