import { Col, Collapse, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import {
  Active,
  ContainerDataInfo,
  ContainerDateSearch,
  ContainerGroupInfo,
  ContainerHeader,
  ContainerInfoGeral,
  ContainerModal,
  ContainerStatus,
  ContainerSurvey,
  LightText,
  Name,
  StrongText,
} from './style'
import EditAnswer from '../EditAnswer'
import { Loading } from '../Loading'
import moment from 'moment'
import api_v2 from '../../services/api-v2'
import EditAnswer2 from '../EditAnswer2'

const { Panel } = Collapse

const DetailSurvey2: React.FC<any> = ({ user, detailVisit, answer, next, back }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>(answer)

  useEffect(() => {
    setLoading(true)
    organizeIdForm()
    setTimeout(() => {
      setLoading(false)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer])

  const compareKeyQuestion = (header: any, answers: any) => {
    let answer: any = []

    header.forEach((el: any) => {
      answers.forEach((el2: any) => {
        if (el.key === el2.id) answer.push(el2)
      })
    })

    return answer
  }

  const organizeIdForm = async () => {
    let configAnswer: any = []
    // for await (let el of answer) {
    //   const { data } = await api_v2.get(`/form/get_header/${el.form_id}`)
    //   let header = data
    //   configAnswer.push({ ...el, form: { ...el.form, header } })
    // }

    // let newAnswer = configAnswer.map((el: any) => {
    //   let getAnswer = el.answer.map((ans: any) => compareKeyQuestion(el.form.header, ans))
    //   let answer: any = [getAnswer]
    //   delete el.form

    //   return {
    //     ...el,
    //     answer,
    //   }
    // })

    setData(answer)

    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  return (
    <ContainerModal>
      <Row gutter={[20, 20]} justify='space-between'>
        <Col>
          <ContainerHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img src={`https://via.placeholder.com/150/ff872c/fff/?text=${user.name?.substr(0, 1)}`} alt='user' />
              <ContainerStatus>
                <Name>{user.name}</Name>
              </ContainerStatus>
            </div>
            {user.is_active ? (
              <Active>Ativo</Active>
            ) : (
              <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>Inativo</Active>
            )}
          </ContainerHeader>
        </Col>
      </Row>
      <Row gutter={[40, 40]} style={{ marginTop: 30 }}>
        <Col span={24}>
          <ContainerSurvey>
            <Row gutter={[40, 40]} style={{ width: '100%' }}>
              <Col span={24}>
                <ContainerInfoGeral>
                  <StrongText>Detalhes da Pesquisa</StrongText>
                  {detailVisit?.status === 'COMPLETE' && <Active>Complete</Active>}
                  {detailVisit?.status === 'PENDENT' && (
                    <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>
                      PENDENTE
                    </Active>
                  )}
                  {detailVisit?.status === 'IN_PROGRESS' && (
                    <Active style={{ color: '#e9830f', borderColor: '#e9830f', backgroundColor: '#edbf95' }}>
                      EM PROGRESSO
                    </Active>
                  )}
                  <Row style={{ marginTop: 30 }}>
                    <Col xs={24} sm={12}>
                      <ContainerGroupInfo>
                        <ContainerDataInfo>
                          <StrongText>ID:</StrongText>
                          <LightText>{detailVisit?.id} </LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText>Rótulo:</StrongText>
                          <LightText>{detailVisit.pdv?.name}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText>Formulário:</StrongText>
                          {answer && <LightText>{answer[0].form_name}</LightText>}
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText>PDV:</StrongText>
                          <LightText>{detailVisit.pdv?.name}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText>Criado em:</StrongText>
                          <LightText>{moment(detailVisit.created_at).format('DD/MM/YYYY HH:mm:ss')}</LightText>
                        </ContainerDataInfo>
                      </ContainerGroupInfo>
                    </Col>
                    <Col xs={24} sm={12}>
                      <ContainerDateSearch>
                        <StrongText style={{ fontSize: 20 }}>Datas da Pesquisa</StrongText>
                        <ContainerDataInfo>
                          <StrongText style={{ fontSize: 14 }}>Data de solitação: </StrongText>
                          <LightText>{moment(detailVisit.created_at).format('DD/MM/YYYY')}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText style={{ fontSize: 14 }}>Data de conclusão: </StrongText>
                          <LightText>{moment(detailVisit.check_out?.date).format('DD/MM/YYYY HH:mm:ss')}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText style={{ fontSize: 14 }}>Data de expiração: </StrongText>
                          <LightText>{moment(detailVisit.expires_date).format('DD/MM/YYYY HH:mm:ss')}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText style={{ fontSize: 14 }}>Data recebimento web: </StrongText>
                          <LightText>{moment(detailVisit.sync_firebase_date).format('DD/MM/YYYY HH:mm:ss')}</LightText>
                        </ContainerDataInfo>
                        <ContainerDataInfo>
                          <StrongText style={{ fontSize: 14 }}>Data recebimento mobile: </StrongText>
                          <LightText>{moment(detailVisit.sync_firebase_date).format('DD/MM/YYYY HH:mm:ss')}</LightText>
                        </ContainerDataInfo>
                      </ContainerDateSearch>
                    </Col>
                  </Row>
                </ContainerInfoGeral>
              </Col>
              {loading && <Loading />}
              <Col span={24}>
                {data.length > 0 &&
                  data.map((el: any, index: number) => (
                    <>
                      {el.answer &&
                        el.answer.length > 0 &&
                        el.answer.map((item: any) => (
                          <Collapse defaultActiveKey={data.map((el: any, index: number) => index)}>
                            <Panel
                              extra={item[0].collected_date || ''}
                              header={el.form_context === 'PRODUCT' ? item[0] && `${el.form_name} - ${item[0].product_name} ${item[0].sub_workspaces ? ` - ${item[0].sub_workspaces}` : ''}` : `${el.form_name} ${item[0].sub_workspaces ? ` - ${item[0].sub_workspaces}` : ''}`}
                              key={index}
                            >
                              {Array.isArray(item) &&
                                item.map((subEl: any) => (
                                  <>
                                    <EditAnswer2
                                      quest={subEl.field_name}
                                      answer={subEl.field_value}
                                      type={subEl.field_type}
                                      all={item}
                                      answer_id={subEl.answer_id}
                                      pdv={detailVisit}
                                      answerId={subEl}
                                      form_id={el.form_id}
                                    />
                                  </>
                                ))}
                            </Panel>
                          </Collapse>
                        ))}
                    </>
                  ))}
              </Col>
            </Row>
          </ContainerSurvey>
        </Col>
      </Row>
    </ContainerModal>
  )
}

export default DetailSurvey2
