import React from 'react'
import { Container } from './styles'
import { Boolean } from './Boolean/index'
import { UniqueChoice } from './UniqueChoices'
import { Text } from './Text'
import { Money } from './Money'
import { MultipleChoise } from './MultipleChoise'
import { Int } from './Int'
import { Picture } from './Picture'
import { DateForm } from './DateForm'
import { DbProducts } from './DbProducts'

interface Props {
  data: any
  product?: any
  handleSetAnswers: (value: any) => void
  error: number[]
  checkRequired: (value: any) => void
  body: any
}

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
}

export const ViewForm = ({ data, handleSetAnswers, checkRequired, error, product, body }: Props) => {
  // const [loading, setLoading] = useState(false)

  const hadleAnswers = (answers: any, dataQuestion: IQuestion) => {
    let data = {
      id: dataQuestion.id,
      field_name: dataQuestion.name,
      field_order: dataQuestion.order,
      field_type: dataQuestion.type,
      field_value: answers,
    }
    // if(dataQuestion.type == 'PICTURE_CAPTURE'){
    //   data.field_value = data.field_value.map((item: any) =>{
    //     return `https://firebasestorage.googleapis.com/v0/b/rocketpdv-dev.appspot.com/o/${user.workspace.slug}%2F${store.id}%2Ftasks%2F${form.forms.id}%2F${item.split('/')[item.split('/').length -1]}?alt=media`
    //   })
    // }

    handleSetAnswers(data)
  }

  return (
    <>
      <Container>
        {data?.fields &&
          data.fields.map((question: any, index: number) => (
            <div key={question.id}>
              {(question.type === 'PICTURE_CAPTURE' || question.type === 'GALLERY') && (
                <Picture
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error.indexOf(question.id) > -1}
                  body={body}
                />
              )}
              {question.type === 'NUMBER_INTEGER' && (
                <Int
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error.indexOf(question.id) > -1}
                />
              )}
              {question.type === 'UNIQUE_CHOICE' && (
                <UniqueChoice
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  error={error.indexOf(question.id) > -1}
                  listError={error}
                  body={body}
                />
              )}
              {question.type === 'DB_PRODUCTS' && (
                <DbProducts
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error.indexOf(question.id) > -1}
                  listError={error}
                  body={body}
                />
              )}
              {question.type === 'BOOLEAN' && (
                <Boolean
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  error={error.indexOf(question.id) > -1}
                  checkRequired={checkRequired}
                  listError={error}
                  body={body}
                />
              )}
              {question.type === 'TEXT' && (
                <Text
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error.indexOf(question.id) > -1}
                />
              )}
              {question.type === 'MULTIPLE_CHOICE' && (
                <MultipleChoise
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error.indexOf(question.id) > -1}
                  listError={error}
                  body={body}
                />
              )}
              {question.type === 'MONEY' && (
                <Money
                  dataQuestion={question}
                  checkRequired={checkRequired}
                  handleAnswers={hadleAnswers}
                  error={error.indexOf(question.id) > -1}
                />
              )}
              {question.type === 'DATE' && (
                <DateForm
                  dataQuestion={question}
                  handleAnswers={hadleAnswers}
                  checkRequired={checkRequired}
                  key={index}
                />
              )}
            </div>
          ))}
      </Container>
    </>
  )
}
