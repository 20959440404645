/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import { Container, FlexTitle } from './style'
import { Input, Upload, Tooltip, Modal, Radio, Image as ImageAntd, message } from 'antd'
import { EditOutlined, PlusOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons'
import { LoadingPicture } from '../LoadingPicture'
import firebase from '../../utils/firebase'
import api_v2 from '../../services/api-v2'
import { Mixpanel } from '../../utils/mixpanel'
import { ModalReportSurveyEdit } from '../ModalReportSurveyEdit'

interface Props {
  quest: any
  answer: any
  color?: any
  type?: any
  all?: any
  pdv?: any
  answerId?: any
  answer_id?: any
  form_id?: any
}
const EditAnswer2: React.FC<Props> = ({ quest, answer, color, type, all, pdv, answerId, answer_id, form_id }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [visiblePicture, setVisiblePicture] = useState(false)
  const [currentPicture, setCurrentPicture] = useState(0)

  if (answer && answer[0] && typeof answer[0] == 'string' && answer[0].toLowerCase() === 'sim') color = 'green'
  if (
    (answer && answer[0] && typeof answer[0] == 'string' && answer[0].toLowerCase() === 'não') ||
    (answer && answer[0] && typeof answer[0] == 'string' && answer[0].toLowerCase() === 'nao')
  )
    color = 'red'

  const editButton = (
    <Tooltip title='Editar resposta'>
      <EditOutlined style={{ fontSize: '20px', cursor: 'pointer', color: 'blue' }} onClick={() => setIsEditing(true)} />
    </Tooltip>
  )


  console.log('EditAnswer2', all)

  return (
    <>
      <Container color={color}>
        <FlexTitle color={color}>
          <label>{quest}</label>
          <p>
            {(type === 'PICTURE_CAPTURE' || type === 'GALLERY') && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {answer.map((el: any, index: number) => (
                  <LoadingPicture
                    el={el}
                    open={() => {
                      setCurrentPicture(index)
                      setVisiblePicture(true)
                    }}
                    index={index}
                    key={index}
                  />
                ))}
                <div
                  style={{ display: 'none' }}
                  onKeyDown={(evt: any) => {
                    if (visiblePicture && evt.key === 'ArrowLeft')
                      setCurrentPicture(currentPicture === 0 ? currentPicture : currentPicture - 1)

                    if (visiblePicture && evt.key === 'ArrowRight')
                      setCurrentPicture(currentPicture === answer.length - 1 ? currentPicture : currentPicture + 1)
                  }}
                >
                  <ImageAntd.PreviewGroup
                    preview={{
                      visible: visiblePicture,
                      onVisibleChange: (vis) => setVisiblePicture(vis),
                      current: currentPicture,
                    }}
                  >
                    {answer.map((el: any, index: number) => {
                      if (typeof el === 'string')
                        return (
                          <ImageAntd
                            onClick={() => {
                              setCurrentPicture(index)
                            }}
                            src={el}
                            key={index}
                          />
                        )
                    })}
                  </ImageAntd.PreviewGroup>
                </div>
              </div>
            )}
            {(type === 'BOOLEAN' ||
              type === 'TEXT' ||
              type === 'DATE' ||
              type === 'MONEY' ||
              type === 'NUMBER_DECIMAL' ||
              type === 'UNIQUE_CHOICE' ||
              type === 'NUMBER_INTEGER' ||
              type === 'DB_PRODUCTS') && (
                <>
                  <p>{answer && answer[0] && typeof answer[0] == 'string' && answer[0].toUpperCase()}</p>
                  <p>{answer && answer[0] && typeof answer[0] == 'number' && answer[0]}</p>
                </>
              )}
            {type === 'MULTIPLE_CHOICE' && answer && <p>{answer.join(' | ')}</p>}
          </p>
          {editButton}
        </FlexTitle>
      </Container>

      {isEditing && (
        <ModalReportSurveyEdit
          visible={isEditing}
          close={setIsEditing}
          body={{
            form_id,
            all,
          }}
        />
      )}
    </>
  )
}

export default EditAnswer2
