import React, { useEffect, useState } from 'react'
import { Container, Title, TextError } from './styles'
import { Select } from 'antd'
import api_v2 from '../../../../../services/api-v2'

interface IQuestion {
  id: number
  is_required: boolean
  name: string
  order: string
  type: string
  fields?: any
  options?: any
}
interface IDataQuestion {
  dataQuestion: IQuestion
  handleAnswers: (value: any, dataQuestion: IQuestion) => void
  error?: boolean
  checkRequired: (value: any) => void
  listError: any
  body: any
}

export const DbProducts = ({
  dataQuestion,
  handleAnswers,
  error,
  checkRequired,
  listError,
  body,
}: IDataQuestion) => {
  const [isRender, setIsRender] = useState(false)
  const [value, setValue] = useState([])
  const [options, setOptions] = useState<any[]>([])

  const handleValue = (check: any) => {
    setValue([check])
  }

  useEffect(() => {
    const answers = () => {
      handleAnswers(value, dataQuestion)
    }

    async function loadProducts() {
      const { data: { result } } = await api_v2.get(`/product?perpage=100`)
      setOptions(result)
    }

    loadProducts()
    answers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (dataQuestion.is_required && !isRender) {
      setIsRender(true)
      checkRequired(dataQuestion.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Title>
        {dataQuestion.order} - {dataQuestion.name} {dataQuestion.is_required && '*'}
      </Title>
      <Select allowClear style={{ width: '100%' }} onChange={handleValue}>
        {options.map((el: any, index: any) => (
          <Select.Option key={index} value={el.name}>
            {el.name}
          </Select.Option>
        ))}
      </Select>

      {error && value.length === 0 && <TextError>Campo obrigatório</TextError>}
    </Container>
  )
}
